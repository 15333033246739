import React from 'react';
import WistiaVideo from './WistiaVideo';
import './styles.scss';
import { Section } from '../Section';
import { EmailForm } from '../EmailForm';

export const Header = ({ id }) => {
  return (
    <Section id={id} className="header" isNotDefault>
      <div className="section__items--flex header__items">
        <div className="header__item--half header__item--smaller">
          <h1 className="header__text--pink">Girls to code</h1>
          <h3 className="header__text--small">
            <p>
              <strong>We teach</strong> girls to code for free.
            </p>
            <p>
              <strong>We build</strong> a digital women society.
            </p>
            <p>
              <strong>We help</strong> to make the first step in IT.
            </p>
          </h3>
          <h2 className="section__title--primary header__text--spaced">
            Stay in touch
          </h2>
          <EmailForm />
        </div>
        <div className="header__item--half header__item--bigger">
          <WistiaVideo />
        </div>
      </div>
    </Section>
  );
};
