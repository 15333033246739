import React from 'react';
import { Section } from '../Section';
import { PostsContext } from '../../context-providers/PostsProvider';
import { PostFigure } from '../PostFigure';
import { CustomButton } from '../Button';
import './styles.scss';

const SIZES = ['large', 'medium', 'small'];

export const LatestBlogPosts = ({ id }) => {
  const { posts } = React.useContext(PostsContext);

  const redirectToBlog = () => (window.location = '/blog');
  return (
    <Section id={id} isWhite>
      <h1 className="section__title--primary">Blog</h1>
      <div className="latest-blog-posts__wrapper">
        {posts.slice(0, 3).map(({ node: post }, index) => (
          <PostFigure
            key={index}
            image={post.frontmatter.thumbnail}
            title={post.frontmatter.title}
            description={post.internal.content}
            date={post.frontmatter.date}
            link={`/blog/${post.frontmatter.path}`}
            size={SIZES[posts.length - 1]}
          />
        ))}
      </div>
      {posts.length > 3 && (
        <CustomButton
          title="See more"
          onClick={() => redirectToBlog()}
          borderRadius="full"
        />
      )}
    </Section>
  );
};
