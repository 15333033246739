import React from 'react';
import './styles.scss';
import CookieConsent from 'react-cookie-consent';
import { CustomButton } from '../Button';
import { Link } from 'gatsby';

export const CookiesBar = () => {
  return (
    <div className="cookies-wrapper">
      <CookieConsent
        disableStyles
        containerClasses="cookies-bar"
        contentClasses="cookies-bar__content"
        ButtonComponent={data => (
          <CustomButton
            color="default"
            title="I understand"
            onClick={data.onClick}
            className="cookies-bar__button"
          />
        )}
      >
        This website uses cookies to enhance the user experience.{' '}
        <Link to="/privacy" className="link--white">
          Learn more...
        </Link>
      </CookieConsent>
    </div>
  );
};
