import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section } from '../Section';
import './styles.scss';

export const About = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "members.jpg" }) {
        childImageSharp {
          fixed(width: 350, height: 450, cropFocus: NORTHWEST) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Section id={id} isWhite>
      <div className="about-us__items">
        <Img
          className="header__item--smaller"
          fixed={data.image.childImageSharp.fixed}
          alt="Our team"
        />
        <div className="header__item--bigger">
          <h1 className="section__title--primary">Who we are</h1>
          <p>
            We believe in <strong>equality</strong> and{' '}
            <strong>equal opportunities</strong> for all people.{' '}
          </p>
          <p>
            <strong>Code</strong> is one of the most vital part in our{' '}
            <strong>modern society</strong>.
          </p>
          <p>
            Working in tech industry gives people amazing{' '}
            <strong>opportunities</strong>.
          </p>
          <p>Yet the number of women involved is still too low.</p>
          <p>
            We believe it is important to <strong>encourage</strong> women to
            learn to code.
          </p>
          <p>
            We want to add our brick to{' '}
            <strong>make the world a better place</strong>.
          </p>
        </div>
      </div>
    </Section>
  );
};
