import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { PostThumbnail } from '../PostThumbnail';
import { DATE_FORMAT, formatTimestamp } from '../../utils/dates';
import { Link } from 'gatsby';
import classNames from 'classnames';

export const PostFigure = ({ image, title, description, link, date, size }) => {
  const shorten = (str, maxLen, separator = ' ') => {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + '...';
  };

  const isLargeSize = size === 'large';

  const postFigureClass = classNames('post-figure', `post-figure--${size}`);
  const thumbnailClass = classNames(
    isLargeSize && 'post-figure__thumbnail--bigger'
  );
  const textWrapperClass = classNames(
    'post-figure__text-wrapper',
    isLargeSize && 'post-figure__text-wrapper--smaller'
  );
  const wrapperClass = classNames(isLargeSize && 'post-figure__wrapper--flex');

  return (
    <article className={postFigureClass}>
      <Link to={link} className="post-figure__link">
        <div className={wrapperClass}>
          <PostThumbnail
            image={image}
            size={isLargeSize ? 'medium' : 'small'}
            className={thumbnailClass}
          />
          <div className={textWrapperClass}>
            <h4 className="post-figure__date">
              {formatTimestamp(date, DATE_FORMAT)}
            </h4>
            <h3 className="post-figure__title">{title}</h3>
            <p className="post-figure__description">
              {shorten(description, 90)}
            </p>
          </div>
        </div>
      </Link>
    </article>
  );
};

PostFigure.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

PostFigure.defaultProps = {
  size: 'small',
};
