import React from 'react';

export default class WistiaVideo extends React.Component {
  
  componentDidMount() {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    
    script1.src = 'https://fast.wistia.com/embed/medias/nkzu4nnk18.jsonp';
    script1.async = true;
    
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;
    
    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }
  
  render() {
    return (
      <div className="wistia_responsive_padding" style={{ padding: '45.16% 0 0 0', position: 'relative' }}>
        <div className="wistia_responsive_wrapper"
             style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}><span
          className="wistia_embed wistia_async_nkzu4nnk18 popover=false popoverAnimateThumbnail=true videoFoam=true endVideoBehavior=loop"
          style={{ display: 'inline-block', height: '100%', position: 'relative', width: '100%' }}>&nbsp;</span></div>
      </div>
    );
  };
}
