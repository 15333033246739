import PropTypes from 'prop-types';
import React from 'react';
import Img from 'gatsby-image';
import './styles.scss';
import classNames from 'classnames';

export const CircleFigure = ({
                               image,
                               imageAlt,
                               title,
                               link,
                               subtitle,
                               className,
                             }) => {
  const computedClass = classNames('circle-figure', className);
  
  return (
    <figure className={computedClass}>
      <Img
        objectPosition="100%"
        fixed={image}
        alt={imageAlt}
        className="circle-figure__image"
      />
      <figcaption className="circle-figure__title">{link? <a style={{color: '#464347'}} href={link}>{title}</a>: title}</figcaption>
      <figcaption>{subtitle}</figcaption>
    </figure>
  );
};

CircleFigure.propTypes = {
  image: PropTypes.object,
  link: PropTypes.string,
  className: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
