import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CircleFigure } from '../CircleFigure';
import './styles.scss';
import { Section } from '../Section';

const ORGANIZER_TRAINER_ROLE = 'Organizer/Trainer';
const ORGANIZER_ROLE = 'Organizer';
const TRAINER_ROLE = 'Trainer';

const MEMBERS = [
  {
    image: '1.jpg',
    name: 'Radosław Grzesiak',
    role: ORGANIZER_TRAINER_ROLE,
    link: "https://programistaodzera.pl"
  },
  {
    image: '3.jpg',
    name: 'Jakub Grzesiak',
    role: ORGANIZER_TRAINER_ROLE,
  },
  {
    image: '2.jpg',
    name: 'Magdalena Rakoczy',
    role: ORGANIZER_ROLE,
  },
  {
    image: '5.png',
    name: 'Ewa Dubaj',
    role: TRAINER_ROLE,
  },
  {
    image: '6.jpg',
    name: 'Paweł Minkina',
    role: TRAINER_ROLE,
  },
  {
    image: '4.png',
    name: 'Konrad Janczyk',
    role: TRAINER_ROLE,
  },
];

export const Team = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "team" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fixed(width: 160, height: 160, cropFocus: NORTH, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);
  
  data.allFile.edges.sort((a, b) => {
    return parseInt(a.node.base.charAt(0)) - parseInt(b.node.base.charAt(0));
  });
  
  return (
    <Section id={id}>
      <h1 className="section__title--primary">Team</h1>
      <div className="section__items--flex team-wrapper">
        {data.allFile.edges.map((image, index) => {
          const member = MEMBERS.find(item => item.image === image.node.base);
          return (
            <CircleFigure
              key={index}
              image={image.node.childImageSharp.fixed}
              imageAlt={member.name}
              className="team-wrapper__figure"
              link={member.link}
              title={member.name}
              subtitle={member.role}
            />
          );
        })}
      </div>
    </Section>
  );
};
