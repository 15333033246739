import React from 'react';
import SEO from '../components/seo';
import { Header } from '../components/Header';
import { Layout } from '../components/Layout';
import { About } from '../components/About';
import { Team } from '../components/Team';
import { Organizer } from '../components/Organizer';
import { Partners } from '../components/Partners';
import { Contact } from '../components/Contact';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import muiTheme from '../utils/muiTheme';
import { CookiesBar } from '../components/CookiesBar';
import { LatestBlogPosts } from '../components/LatestBlogPosts';

const IndexPage = ({ location }) => {
  const section = location.hash.substring(1);
  React.useEffect(() => {
    if (section && document.getElementById(section)) {
      setTimeout(() => document.getElementById(section).scrollIntoView(), 1000);
    }
  }, [section]);

  return (
    <ThemeProvider theme={muiTheme}>
      <Layout isShifted>
        <SEO title="Girls To Code - learn to code for free!"/>
        <Header id="header" />
        <About id="about" />
        <Team id="team" />
        <Organizer id="organizer" />
        <Partners id="partners" />
        <LatestBlogPosts id="latest-blog-posts" />
        <Contact id="contact" />
        <CookiesBar />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
