import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { CircleFigure } from '../CircleFigure';
import './styles.scss';
import { Section } from '../Section';

export const Organizer = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      luxdoneLogo: file(relativePath: { eq: "luxdone-logo.png" }) {
        childImageSharp {
          fixed(width: 170, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      member: file(relativePath: { eq: "maciejstrama.jpg" }) {
        childImageSharp {
          fixed(width: 160, height: 160, cropFocus: NORTH, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Section id={id} isWhite>
      <h1 className="section__title--primary">Organizer</h1>
      <div className="section__items--flex organizer">
        <div className="organizer__member">
          <Img
            fixed={data.luxdoneLogo.childImageSharp.fixed}
            alt="Luxdone logo"
          />
          <CircleFigure
            image={data.member.childImageSharp.fixed}
            imageAlt="maciejstrama"
            title="Maciej Strama"
            subtitle="Co-Founder"
          />
        </div>
        <div className="organizer__description">
          <p>
            Luxdone helps companies to build great Teams and create amazing
            products. Luxdone was created by engineers, visionaries and
            entrepreneurs, all passionate about working with tech. Luxdone
            mission is to connect A-player developers with top companies and
            create best environment for them, where they can grow and become
            part of amazing journey. We are committed to increase diversity in
            Tech companies.{' '}
            <a
              href="https://luxdone.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more...
            </a>
          </p>
          <p>
            <strong>
              We created Girls to Code to show that coding can be fun and gives
              life changing opportunities.
            </strong>
          </p>
        </div>
      </div>
    </Section>
  );
};
