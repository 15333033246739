import React from 'react';
import './styles.scss';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import { CustomButton as Button } from '../Button';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export const EmailForm = () => {
  const [submittedStatus, setSubmittedStatus] = React.useState(null);

  const handleSubmit = async event => {
    return await addToMailchimp(event.email);
  };

  const responseMessages = {
    error: 'You are already subscribed to our newsletter.',
    success: 'Thank you for joining our community!',
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit, form, submitting, pristine }) => (
          <form
            onSubmit={event => {
              handleSubmit(event).then(response => {
                setSubmittedStatus(response.result);
                setTimeout(form.reset, 1000);
              });
            }}
          >
            <Field name="email">
              {({ input }) => (
                <TextField
                  {...input}
                  type="email"
                  label="E-mail"
                  variant="outlined"
                  size="medium"
                  classes={{ root: 'form__field' }}
                />
              )}
            </Field>
            <Button
              type="submit"
              title="Submit"
              disabled={submitting || pristine}
              classes={{ root: 'form__button' }}
            />
            {submittedStatus && (
              <span className="form__message">
                {responseMessages[submittedStatus]}
              </span>
            )}
          </form>
        )}
      </Form>
    </>
  );
};
