import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

import albacross from '../../images/partners/albacross.png';
import babaliste from '../../images/partners/babaliste.png';
import karming from '../../images/partners/karming.png';
import paytrim from '../../images/partners/paytrim.png';
import teamtailor from '../../images/partners/teamtailor.png';
import luxdone from '../../images/luxdone-logo.png';

const LOGOS = [teamtailor, karming, albacross, babaliste, paytrim, luxdone];

export const PartnersSlider = () => {
  const SETTINGS = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...SETTINGS} className="partners-slider">
      {LOGOS.map((image, index) => (
        <div className="partners-slider__item" key={index}>
          <img src={image} alt={image.split('/')[2]} />
        </div>
      ))}
    </Slider>
  );
};
