import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { CircleFigure } from '../CircleFigure';
import './styles.scss';
import { PartnersSlider } from '../PartnersSlider';
import { Section } from '../Section';

export const Partners = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      teamtailor: file(relativePath: { eq: "partners/teamtailor.png" }) {
        childImageSharp {
          fixed(width: 170, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      albacross: file(relativePath: { eq: "partners/albacross.png" }) {
        childImageSharp {
          fixed(width: 140, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      babaliste: file(relativePath: { eq: "partners/babaliste.png" }) {
        childImageSharp {
          fixed(width: 200, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      karming: file(relativePath: { eq: "partners/karming.png" }) {
        childImageSharp {
          fixed(width: 150, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      paytrim: file(relativePath: { eq: "partners/paytrim.png" }) {
        childImageSharp {
          fixed(width: 180, height: 46) {
            ...GatsbyImageSharpFixed
            aspectRatio
          }
        }
      }
      luxdone: file(relativePath: { eq: "luxdone-logo.png" }) {
        childImageSharp {
          fixed(width: 190, height: 42) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      suzan: file(relativePath: { eq: "partners/suzan.png" }) {
        childImageSharp {
          fixed(width: 160, height: 160, cropFocus: NORTH, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      erik: file(relativePath: { eq: "partners/erik.jpg" }) {
        childImageSharp {
          fixed(width: 160, height: 160, cropFocus: NORTH, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      victor: file(relativePath: { eq: "partners/victor.jpeg" }) {
        childImageSharp {
          fixed(width: 160, height: 160, cropFocus: NORTH, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sammy: file(relativePath: { eq: "partners/sammy.png" }) {
        childImageSharp {
          fixed(width: 160, height: 160, cropFocus: NORTH, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Section id={id}>
      <h1 className="section__title--primary">Partners</h1>
      <div className="section__items--flex">
        <div className="partners__item--half">
          <Img
            fixed={data.teamtailor.childImageSharp.fixed}
            alt="Teamtailor Logo"
          />
          <CircleFigure
            image={data.suzan.childImageSharp.fixed}
            imageAlt="Suzan"
            title="Suzan Dil"
            subtitle="HEAD OF PRODUCT COMMUNICATION"
          />
          <p>
            Teamtailor is giving companies the best tool to attract and recruit
            candidates. Teamtailor combines a platform for employer branding
            with a powerful tool for recruitment, giving over 1300 companies all
            the means they need to grow their businesses. Teamtailor makes it
            possible for companies to give their candidates the job application
            process they deserve.
          </p>
          <p>
            <strong>
              We believe diversity is a key to our success, and we always strive
              to improve our work for diversity and make our team even better.
              Girls To Code is a great initiative that will make sure that more
              people see the tech industry as a potential place to work.
            </strong>
          </p>
        </div>
        <div className="partners__item--half">
          <Img
            fixed={data.albacross.childImageSharp.fixed}
            alt="Albacross Logo"
          />
          <CircleFigure
            image={data.victor.childImageSharp.fixed}
            imageAlt="Victor"
            title="VICTOR EKELUND"
            subtitle="CEO"
          />
          <p>
            Albacross is a powerful lead generation tool that allows B2B
            marketers to generate leads from their company’s website
            automatically. You’ll get an overview of which companies visited
            your site, and how they interacted with your platform. You’ll also
            have access to crucial information such as the pages they have
            visited, their chief decision-makers, and the ways they can be
            reached or contacted.
          </p>
          <p>
            <strong>
              Albacross believes that there is a lot of opportunities in coding
              for everyone, and that each gender will have a role to play. We
              are here to join the fight in bridging that gender gap in the tech
              world, and break all the stereotypes regarding girls who code.
            </strong>
          </p>
        </div>
      </div>

      <div className="section__items--flex">
        <div className="partners__item--half">
          <Img fixed={data.paytrim.childImageSharp.fixed} alt="Paytrim Logo" />
          <CircleFigure
            image={data.erik.childImageSharp.fixed}
            imageAlt="Erik"
            title="Erik"
            subtitle="HEAD OF MARKETING"
          />
          <p>
            Paytrim is a fintech company giving SME business owners more
            profitable and transparent payment transactions.
          </p>
          <p>
            <strong>
              We fully support Girls to code and hope the the event will spark a
              flame and get more girls into coding.
            </strong>
          </p>
        </div>
        <div className="partners__item--half">
          <Img
            fixed={data.babaliste.childImageSharp.fixed}
            alt="Paytrim Logo"
          />
          <CircleFigure
            image={data.sammy.childImageSharp.fixed}
            imageAlt="Sammy"
            title="SAMMY BEN ABLA"
            subtitle="CEO"
          />
          <p>
            Babaliste is North Africa's fastest growing next-gen mobile
            marketplace with over 2 million downloads.
          </p>
          <p>
            <strong>
              We believe Luxdone is doing a great job, involving more girls into
              coding and tech, and we of course want to support that.
            </strong>
          </p>
        </div>
      </div>

      <PartnersSlider />
    </Section>
  );
};

export default Partners;
